import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt} from "@fortawesome/free-solid-svg-icons";
import {Checkbox, DatePicker} from 'antd';
import {useContext, useEffect, useState} from "react";
import {ApiConsumer} from "../api/ApiConsumer";
import {API_URLS} from "../config";
import {useNotification} from "../hooks/NotificationHook";
import {useAuthentication} from "../hooks/AuthHook";
import RichTextEditor from "react-rte";
import {useUtils} from "../hooks/utils";
import moment from "moment";
import {useHistory} from "react-router";
import {CONSTANTS} from "../config/constants";
import {ApploadContext} from "../contexts/Appload";

export const Detail = ({job = {}}) => {
    let {textEditorFormatter} = useUtils()
    const [detail, setDetail] = useState(job)
    const [type, setTypes] = useState([])
    const [hospitals, setHospitals] = useState([])
    const [departments, setDepartments] = useState([])
    const [competencies, setCompetencies] = useState([])
    const [selectedSelectedHospital, setSelectedHospital] = useState('')
    const [req, setReq] = useState(textEditorFormatter(job?.requirements))
    const [critic, setCritic] = useState(textEditorFormatter(job?.critical_outputs))
    const [des, setDes] = useState(textEditorFormatter(job?.description))
    const [notes, setNotes] = useState(textEditorFormatter(job?.notes))
    const [templates, setTemplates] = useState([])
    const [template, setTemplate] = useState({})

    let {SuccessMsg, ErrorMsg} = useNotification();
    let {user} = useAuthentication();
    let {propertyExists} = useUtils();
    let {setLoading} = useContext(ApploadContext)
    let history = useHistory()


    useEffect(() => {
        ApiConsumer.get(API_URLS.JOBS.CONTRACT_TYPE)
            .then(res => setTypes(res.data))
            .catch(err => console.error(err))

        ApiConsumer.get(API_URLS.DATA.HOSPITALS)
            .then(res => setHospitals(res.data.data))
            .catch(err => console.error(err))

        ApiConsumer.get(API_URLS.JOBS.COMPETENCIES)
            .then(res => setCompetencies(res.data))
            .catch(err => console.error(err))

        ApiConsumer.get(API_URLS.JOBS.DEPARTMENTS)
            .then(res => setDepartments(res.data))
            .catch(err => console.error(err))

        ApiConsumer.get(API_URLS.DATA.HOSPITALS)
            .then(res => setHospitals(res.data.data))
            .catch(err => console.error(err))

        ApiConsumer.get(API_URLS.JOBS.TEMPLATES)
            .then(res => setTemplates(res.data))
            .catch(err => console.error(err))

        setDetail(job)
        setReq(textEditorFormatter(job?.requirements))
        setCritic(textEditorFormatter(job?.critical_outputs))
    }, [job])

    useEffect(() => {
        if (detail?.template_id !== 'None' && detail?.template_id !== undefined) {
            ApiConsumer.get(API_URLS.JOBS.TEMPLATES_DETAIL(detail?.template_id))
                .then(res => {
                    console.log(RichTextEditor.createValueFromString(res.data.description, 'html'))
                    setTemplate(res.data)
                    setDetail(prevState => ({
                        ...prevState,
                        ['description']: res.data.description
                    }))
                    setDes(RichTextEditor.createValueFromString(res.data.description, 'html'))

                    setDetail(prevState => ({
                        ...prevState,
                        ['critical_outputs']: res.data.critical_outputs
                    }))
                    setCritic(RichTextEditor.createValueFromString(res.data.critical_outputs, 'html'))

                    setDetail(prevState => ({
                        ...prevState,
                        ['requirements']: res.data.requirements
                    }))
                    setReq(RichTextEditor.createValueFromString(res.data.requirements, 'html'))

                    setDetail(prevState => ({
                        ...prevState,
                        ['competencies']: res.data.competencies
                    }))
                    setCompetencies(res.data.competencies)

                    setDetail(prevState => ({
                        ...prevState,
                        ['notes']: res.data.notes
                    }))
                    setNotes(RichTextEditor.createValueFromString(res.data.notes, 'html'))
                })
                .catch(err => console.error(err))
        }

    }, [detail?.template_id])

    function onChange(date, dateString) {
        setDetail(prevState => ({
            ...prevState,
            ['closing_date']: dateString
        }))
    }

    function propertyChecked(list, id) {
        if (list === null || list === undefined) {
            return false
        }

        return list.find(obj => {
            return obj.id === id
        })
    }

    function onPropertyChange(property, value, name = null) {
        let oldValue = detail[property]
        if (oldValue === null || oldValue === undefined)
            oldValue = []

        let oldObj = oldValue.find(obj => {
            return obj.id === value
        })

        if (oldValue.indexOf(oldObj) !== -1) {
            oldValue.splice(oldValue.indexOf(oldObj), 1)
        } else {
            oldValue.push({id: value, name: name});
        }

        setDetail(prevState => ({
            ...prevState,
            [property]: oldValue
        }))
    }

    function createDate() {
        if (detail?.closing_date === null || detail?.closing_date === undefined) {
            return moment()
        }
        return moment(detail?.closing_date, "YYYY-MM-DD")
    }

    function publish(status) {
        setLoading(true)
        detail.hospital_id = user().is_admin ? selectedSelectedHospital : user().hospital.id
        detail.user_id = user().id
        detail.status = status

        if (detail?.id !== null && detail?.id !== undefined) {
            ApiConsumer.put(API_URLS.JOBS.DETAIL(detail?.id), detail)
                .then(() => {
                    SuccessMsg('Job saved successfully')
                })
                .catch(err => console.log(err))
                .finally(() => {
                    setLoading(false)
                    history.push('/')
                })
            return;
        }

        function validate_text(input) {
            return !(input === null || input === undefined || input === "");

        }

        function validate_selection(input) {
            return !(input === null || input?.length < 1);

        }

        if (!validate_text(detail.closing_date)) {
            ErrorMsg('Please select a closing date')
            setLoading(false)
            return
        }
        if (!validate_text(detail.title)) {
            ErrorMsg('Please provide a job title')
            setLoading(false)
            return
        }
        if (!validate_text(detail.contract_type_id)) {
            ErrorMsg('Please select a contract type')
            setLoading(false)
            return
        }
        if (!validate_text(detail.description)) {
            ErrorMsg('Please provide a job description')
            setLoading(false)
            return
        }
        if (!validate_text(detail.critical_outputs)) {
            ErrorMsg('Please provide critical outputs')
            setLoading(false)
            return
        }
        if (!validate_text(detail.requirements)) {
            ErrorMsg('Please provide requirements')
            setLoading(false)
            return
        }
        if (!validate_selection(detail.competencies)) {
            ErrorMsg('Please select at least 1 competency')
            setLoading(false)
            return
        }
        if (!validate_text(detail.hospital_id)) {
            ErrorMsg('Please select a hospital')
            setLoading(false)
            return
        }
        if (!validate_selection(detail.industries)) {
            ErrorMsg('Please select at least 1 industry')
            setLoading(false)
            return
        }
        if (!validate_selection(detail.departments)) {
            ErrorMsg('Please select at least 1 department')
            setLoading(false)
            return
        }
        ApiConsumer.post(API_URLS.JOBS.DETAIL('new'), detail)
            .then(() => {
                SuccessMsg('Job saved successfully')
            })
            .catch(err => console.log(err))
            .finally(() => {
                history.push('/')
                setLoading(false)
            })
    }

    return <div className="content p-0">
        <div className="tab-pane fade active show">
            <div className="card">
                <div className="card-footer">
                    {
                        detail?.status === CONSTANTS.JOB_STATUS.PUBLISHED ?
                            <span className="badge bg-pps-gold mt-2 mr-1">Published</span> : null
                    }
                    {
                        detail?.status === CONSTANTS.JOB_STATUS.DRAFT ?
                            <span className="badge bg-pps-blue mt-2 mr-1">In Draft</span> : null
                    }
                    {
                        detail?.status === CONSTANTS.JOB_STATUS.CLOSED ?
                            <span className="badge bg-danger mt-2 mr-1">Closed</span> : null
                    }
                    <button
                        onClick={() => publish(CONSTANTS.JOB_STATUS.PUBLISHED)}
                        className="btn btn-sm rounded-round bg-pps-gold btn-lg legitRipple ml-1 mt-1 float-right"
                        data-toggle="modal" data-target="#modal-publish-job">Publish
                    </button>
                    <button
                        onClick={() => publish(CONSTANTS.JOB_STATUS.DRAFT)}
                        className="btn btn-sm rounded-round bg-pps-blue btn-lg legitRipple ml-1 mt-1 float-right"
                        data-toggle="modal" data-target="#modal-draft-job">Save as Draft
                    </button>
                    {
                        Object.keys(detail).length > 0 ? <button
                            onClick={() => publish(CONSTANTS.JOB_STATUS.PUBLISHED)}
                            className="btn btn-sm rounded-round bg-pps-blue btn-lg legitRipple ml-1 mt-1 float-right"
                            data-toggle="modal" data-target="#modal-update-job">Update
                        </button> : null
                    }
                </div>
                <div className="card-body">
                    <div className="form-group">
                        <div className="row">
                            <div className="col-sm-12">
                                <label className="font-weight-semibold mb-0">Job Templates:
                                    <span className="text-danger-800">*</span>
                                </label>
                                <div className="input-group">
                                    <span className="input-group-prepend">
                                        <span className="input-group-text">
                                            <FontAwesomeIcon className="icon-calendar22" icon={faCalendarAlt}/>
                                        </span>
                                    </span>
                                    <select className="form-control form-control-uniform"
                                            onChange={event => setDetail(prevState => ({
                                                ...prevState,
                                                ['template_id']: event.target.value
                                            }))}
                                            value={detail?.template?.id}
                                    >
                                        <option key="None" value="None">No</option>
                                        {
                                            templates.map(template => {
                                                return <option key={template.id}
                                                               value={template.id}>{template.title}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-sm-12">
                                <label className="font-weight-semibold mb-0">Closing date:
                                    <span className="text-danger-800">*</span>
                                </label>
                                <div className="input-group">
                                    <span className="input-group-prepend">
                                        <span className="input-group-text">
                                            <FontAwesomeIcon className="icon-calendar22" icon={faCalendarAlt}/>
                                        </span>
                                    </span>
                                    <DatePicker type="datetime" className="form-control daterange-single"
                                                defaultValue={createDate()}
                                                selected={createDate()} onChange={onChange}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-sm-12">
                                <h4 className="font-weight-semibold mb-0">Job Title:<span
                                    className="text-danger-800">*</span></h4>
                                <input type="text" className="form-control" onChange={event => setDetail(prevState => ({
                                    ...prevState,
                                    ['title']: event.target.value
                                }))} value={detail?.title}/>
                            </div>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="font-weight-semibold mb-0 col-lg-4">Employment Contract Type:<span
                            className="text-danger-800">*</span></label>
                        <div className="col-lg-8">
                            <select className="form-control form-control-uniform"
                                    onChange={event => setDetail(prevState => ({
                                        ...prevState,
                                        ['contract_type_id']: event.target.value
                                    }))}
                                    value={detail?.contract_type?.id}
                            >
                                {
                                    type.map((type, index) => {
                                        return <option key={index} value={type.id}>{type.name}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-sm-12">
                                <label className="font-weight-semibold mb-0">Brief Description:
                                    <span className="text-danger-800">*</span>
                                </label>
                                <RichTextEditor
                                    className="new-post-editor"
                                    value={des}
                                    onChange={text => {
                                        setDes(text)
                                        setDetail(prevState => ({
                                            ...prevState,
                                            ['description']: text.toString("html")
                                        }))
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-sm-12">
                                <label className="font-weight-semibold mb-0">Critical Outputs: <span
                                    className="text-danger-800">*</span>
                                </label>
                                <RichTextEditor
                                    className="new-post-editor"
                                    value={critic}
                                    onChange={text => {
                                        setCritic(text)
                                        setDetail(prevState => ({
                                            ...prevState,
                                            ['critical_outputs']: text.toString("html")
                                        }))
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-sm-12">
                                <label className="font-weight-semibold mb-0">Requirements:
                                    <span className="text-danger-800">*</span>
                                </label>

                                <RichTextEditor
                                    className="new-post-editor"
                                    value={req}
                                    onChange={text => {
                                        setReq(text)
                                        setDetail(prevState => ({
                                            ...prevState,
                                            ['requirements']: text.toString("html")
                                        }))
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-sm-12">
                                <label className="font-weight-semibold ">Competencies: <span
                                    className="text-danger-800">*</span></label>
                            </div>
                            {
                                competencies.map((competency, index) => {
                                    return <div key={index} className="col-sm-4 mb-3">
                                        <div className="form-check">
                                            <label className="form-check-label">
                                                <Checkbox className="form-check-input-styled"
                                                          defaultChecked={propertyExists(job?.competencies, competency.id)}
                                                          onChange={() => onPropertyChange("competencies", competency.id, competency.name)}>{competency.name}</Checkbox>
                                            </label>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>

                    {
                        user()?.is_admin ?
                            <div className="form-group row">
                                <label className="font-weight-semibold mb-0 col-lg-4">Hospital:<span
                                    className="text-danger-800">*</span></label>
                                <div className="col-lg-8">
                                    <select className="form-control form-control-uniform"
                                            onChange={event => setSelectedHospital(event.target.value)}
                                            value={detail?.hospital?.id}
                                    >
                                        {
                                            hospitals.map((hospital, index) => {
                                                return <option key={index} value={hospital.id}>{hospital.name}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div> : null
                    }

                    <div className="form-group">
                        <div className="row">
                            <div className="col-sm-12">
                                <label className="font-weight-semibold mb-0">Notes:
                                    <span className=""> (optional)</span>
                                </label>
                                <RichTextEditor
                                    className="new-post-editor"
                                    value={notes}
                                    onChange={text => {
                                        setNotes(text)
                                        setDetail(prevState => ({
                                            ...prevState,
                                            ['notes']: text.toString("html")
                                        }))
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <div className="row">
                            <div className="col-sm-12">
                                <label className="font-weight-semibold ">Departments: <span
                                    className="text-danger-800">*</span></label>
                            </div>
                            {
                                departments.map((department, index) => {
                                    return <div key={index} className="col-sm-4 mb-2">
                                        <div className="form-check">
                                            <label className="form-check-label">
                                                <Checkbox className="form-check-input-styled"
                                                          value={propertyChecked(job?.departments, department.id)}
                                                          defaultChecked={propertyExists(job?.departments, department.id)}
                                                          onChange={() => onPropertyChange("departments", department.id, department.name)}>{department.name}</Checkbox>
                                            </label>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
