import Empty from "../../components/empty";
import {Row} from "./row";
import Loader from "../../components/loader";
import {Pagination} from "antd";
import {useContext, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import {ApiConsumer} from "../api/ApiConsumer";
import {API_URLS} from "../config";
import {TitleContext} from "../contexts/Title";
import {UserModal} from "./modal";
import {UserDetail} from "components/user/detail";
import {UserDelete} from "components/user/delete";

export const Users = ({page_title, user_type, no_users = 'No Users'}) => {
    const [users, setUsers] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [total, setTotal] = useState(0);
    const [visible, setVisible] = useState(false)
    const [modalDetail, setModalDetail] = useState({})
    const [showModal, setShowModal] = useState(false)
    const [showDelete, setShowDelete] = useState(false)


    let {setTitle, setButton} = useContext(TitleContext);

    function toggleModal() {
        setShowModal(!showModal)
    }

    function toggleDelete(){
        setShowDelete(!showDelete)
    }

    useEffect(() => {
        setTitle(`${page_title}`)
        setButton(<div onClick={() => setVisible(true)} className="btn btn-labeled btn-labeled-right bg-primary">Add
            User
            <b><FontAwesomeIcon icon={faPlusCircle}/></b></div>
        )
    }, [])

    useEffect(() => {
        const filters = {
            'size': pageSize,
            'page': page,
            'user_type': user_type
        }
        ApiConsumer(API_URLS.USER_LIST, {
            params: filters,
            paramSerializer: function (params) {
                return JSON.stringify(params)
            }
        })
            .then(res => {
                setUsers(res.data.data)
                setTotal(res.data?.count ?? 0)
                setTitle(`${page_title} (${res.data?.count ?? 0})`)
                setPage(res.data?.page ?? 1)
                setPageSize(res.data?.size ?? 20)
            })
            .catch(err => console.error(err))
            .finally(() => {
                setLoaded(true)
            })
    }, [page])

    return <div className="card">
        <UserModal setVisible={setVisible} visible={visible} userType={user_type}/>
        <UserDetail toggleDetail={toggleModal} visible={showModal} detail={modalDetail}/>
        <UserDelete detail={modalDetail} visible={showDelete} toggleDetail={toggleDelete}/>
        <div className="datatable-header">
            <div id="DataTables_Table_0_filter" className="dataTables_filter"><label><span>Filter:</span> <input
                type="search" className="" placeholder="Type to filter..."
                aria-controls="DataTables_Table_0"/></label></div>
            <div className="dataTables_length" id="DataTables_Table_0_length"><label><span>Show:</span> <select
                name="DataTables_Table_0_length" aria-controls="DataTables_Table_0"
                className="select2-hidden-accessible" tabIndex="-1" aria-hidden="true">
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
            </select><span
                className="select2 select2-container select2-container--default select2-container--below"
                dir="ltr" style={{width: "auto"}}><span className="selection"><span
                className="select2-selection select2-selection--single" role="combobox" aria-haspopup="true"
                aria-expanded="false" tabIndex="0"
                aria-labelledby="select2-DataTables_Table_0_length-6l-container"><span
                className="select2-selection__rendered" id="select2-DataTables_Table_0_length-6l-container"
                title="10">10</span><span className="select2-selection__arrow" role="presentation">
                <b role="presentation"/></span></span></span><span className="dropdown-wrapper"
                                                                   aria-hidden="true"/></span></label>
            </div>
        </div>
        <table className="table datatable-hospitals table-hover">

            <thead>
            <tr>
                <th>User</th>
                <th>Email Address</th>
                <th>Contact Number / Ext</th>
                <th>Hospital</th>
                <th className='text-center'>Delete</th>
            </tr>
            </thead>
            <tbody>
            {
                loaded ?
                    users.length === 0 ?
                        <tr>
                            <td colSpan={4}><Empty description={no_users}/></td>
                        </tr> :
                        users.map((user, index) => {
                            return <Row key={index} user={user} toggleModal={toggleModal}
                                        setModalDetail={setModalDetail} toggleDelete={toggleDelete}/>
                        }) : <tr>
                        <td colSpan={4}><Loader/></td>
                    </tr>
            }
            </tbody>
        </table>
        <div className="datatable-footer">
            <div className="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">
                Showing {page} to {pageSize} of {total} entries
            </div>
            <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
                <Pagination className="pagination shadow-1 text-center"
                            total={total}
                            current={page}
                            pageSize={20}
                            onChange={selected => {
                                setPage(selected)
                            }}
                />
            </div>
        </div>
    </div>
}
