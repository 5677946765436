import {useUtils} from "../hooks/utils";
import {NavLink} from "react-router-dom";
import {Modal} from 'antd';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import {useContext, useState} from "react";
import {ApiConsumer} from "../api/ApiConsumer";
import {API_URLS} from "../config";
import {useNotification} from "../hooks/NotificationHook";
import parse from 'html-react-parser';
import {CONSTANTS} from "../config/constants";
import {ApploadContext} from "../contexts/Appload";

const Job = ({job}) => {
    const [visible, setVisible] = useState(false)
    let {setLoading} = useContext(ApploadContext)
    let {convertDateToString} = useUtils()
    let {SuccessMsg, ErrorMsg} = useNotification();

    function close_job() {
        setLoading(true)
        ApiConsumer.put(API_URLS.JOBS.DETAIL(job.id), {status: CONSTANTS.JOB_STATUS.CLOSED})
            .then(() => {
                SuccessMsg('Job has successfully been closed.')
                window.location.reload()
            })
            .catch(() => ErrorMsg('Could not close job. Please try again later.'))
            .finally(() => setLoading(false))
    }

    return <div className='col-md-6 col-sm-12'>
        <div key={job.id} className="card pl-1 pr-1">
            <Modal
                title={`Confirm Close Job`}
                visible={visible}
                onOk={close_job}
                icon={<ExclamationCircleOutlined/>}
                onCancel={() => setVisible(false)}
                okText="Yes"
                cancelText="Cancel"
            >
                <p>Are you sure you want to close this job?</p>
            </Modal>
            <div className="card-footer">
                {
                    job?.status === CONSTANTS.JOB_STATUS.PUBLISHED ?
                        <span className="badge bg-pps-gold mt-2 mr-1">Published</span> : null
                }
                {
                    job?.status === CONSTANTS.JOB_STATUS.DRAFT ?
                        <span className="badge bg-pps-blue mt-2 mr-1">In Draft</span> : null
                }
                {
                    job?.status === CONSTANTS.JOB_STATUS.CLOSED ?
                        <span className="badge bg-danger mt-2 mr-1">Closed</span> : null
                }
                {
                    job?.status !== 'CLOSED' ?
                        <button className="btn btn-sm rounded-round bg-danger btn-lg legitRipple ml-1 mt-1 float-right"
                                data-toggle="modal" data-target="#modal-close-job"
                                onClick={() => setVisible(true)}>Close
                        </button> : null
                }
                <NavLink className="btn btn-sm rounded-round bg-pps-blue btn-lg legitRipple ml-1 mt-1 float-right"
                         to={`/jobs/${job.id}/edit`}>
                    Edit
                </NavLink>
            </div>

            <div className="card-body">
                <div className="media">
                    <div className="media-body">
                        <h6 className="media-title font-weight-semibold">
                            <NavLink to={`/jobs/${job.id}`}>
                                {job.title}
                            </NavLink>
                        </h6>
                        <ul className="list-inline text-muted mb-2">
                            <li className="list-inline-item">Posted: {convertDateToString(job.created)}</li>
                            <li className="list-inline-item">|</li>
                            <li className="list-inline-item">Closes: {job.closing_date}</li>
                            <li className="list-inline-item">|</li>
                            <li className="list-inline-item">Updated: {convertDateToString(job.updated)}</li>
                            <li className="list-inline-item">|</li>
                            <li className="list-inline-item">Posted by: {`${job.submitting_user?.first_name} ${job.submitting_user?.last_name}`}</li>
                        </ul>
                    </div>
                </div>
                <p>{parse(job?.description)}</p>
                <div className="clearfix"/>
                <NavLink to={`/applications/jobs/${job.id}`}
                         className="btn btn-sm rounded-round bg-pps-gold btn-lg legitRipple float-left">View
                    Applications</NavLink>
                <NavLink className="btn btn-sm bg-pps-blue rounded-round legitRipple float-right"
                         to={`/jobs/${job.id}`}>
                    Read more
                </NavLink>
            </div>
        </div>
    </div>
}
export default Job;
