import {NavLink} from "react-router-dom";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {Modal} from "antd";
import {useUtils} from "../hooks/utils";
import {useNotification} from "../hooks/NotificationHook";
import {ApiConsumer} from "../api/ApiConsumer";
import {API_URLS} from "../config";
import {useContext, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGlobe} from "@fortawesome/free-solid-svg-icons";
import parse from 'html-react-parser';
import {CONSTANTS} from "../config/constants";
import {ApploadContext} from "../contexts/Appload";

export const Preview = ({job}) => {
    const [visible, setVisible] = useState(false)
    let {convertDateToString, htmlFormatter} = useUtils()
    let {SuccessMsg, ErrorMsg} = useNotification();
    let {setLoading} = useContext(ApploadContext)

    function close_job() {
        setLoading(true)
        ApiConsumer.put(API_URLS.JOBS.DETAIL(job.id), {status: CONSTANTS.JOB_STATUS.CLOSED})
            .then(() => {
                SuccessMsg('Job has successfully been closed.')
                window.location.reload()
            })
            .catch(() => ErrorMsg('Could not close job. Please try again later.'))
            .finally(()=>setLoading(false))
    }

    return <div className="content col-12">
        <Modal
            title={`Confirm Close Job`}
            visible={visible}
            onOk={close_job}
            icon={<ExclamationCircleOutlined/>}
            onCancel={() => setVisible(false)}
            okText="Yes"
            cancelText="Cancel"
        >
            <p>Are you sure you want to close this job?</p>
        </Modal>
        <div className="d-md-flex align-items-md-start">
            <div className="tab-content col-12">
                <div className="tab-pane fade active show">

                    <div className="card">
                        <div className="card-footer">
                            {
                                job?.status === CONSTANTS.JOB_STATUS.PUBLISHED ?
                                    <span className="badge bg-pps-gold mt-2 mr-1">Published</span> : null
                            }
                            {
                                job?.status === CONSTANTS.JOB_STATUS.DRAFT ?
                                    <span className="badge bg-pps-blue mt-2 mr-1">In Draft</span> : null
                            }
                            {
                                job?.status === CONSTANTS.JOB_STATUS.CLOSED ?
                                    <span className="badge bg-danger mt-2 mr-1">Closed</span> : null
                            }
                            <FontAwesomeIcon className='mr-1' icon={faGlobe}/>
                            <a href="#">View on Website</a>
                            <button
                                className="btn btn-sm rounded-round bg-danger btn-lg legitRipple ml-1 mt-1 float-right"
                                data-toggle="modal" data-target="#modal-close-job"
                                onClick={() => setVisible(true)}>Close
                            </button>
                            <NavLink
                                className="btn btn-sm rounded-round bg-pps-blue btn-lg legitRipple ml-1 mt-1 float-right"
                                to={`/jobs/${job.id}/edit`}>
                                Edit
                            </NavLink>
                        </div>
                        <div className="card-body">
                            <div className="media flex-column flex-md-row mb-2">
                                <div className="media-body">
                                    <h5 className="media-title font-weight-semibold">{job?.title}</h5>
                                    <ul className="list-inline text-muted mb-2">
                                        <li className="list-inline-item">Posted: {convertDateToString(job.created)}</li>
                                        <li className="list-inline-item">|</li>
                                        <li className="list-inline-item">Closes: {job.closing_date}</li>
                                        <li className="list-inline-item">|</li>
                                        <li className="list-inline-item">Updated: {convertDateToString(job.updated)}</li>
                                        <li className="list-inline-item">|</li>
                                        <li className="list-inline-item">Posted by: {`${job.submitting_user?.first_name} ${job.submitting_user?.last_name}`}</li>
                                    </ul>
                                </div>

                                <div className="align-self-md-center ml-md-3 mt-2 mt-md-0">
                                    <NavLink to={`/applications/jobs/${job?.id}`}
                                             className="btn btn-md rounded-round bg-pps-gold btn-lg legitRipple">View
                                        Applications</NavLink>
                                </div>
                            </div>


                            <ul className="list-inline list-inline-condensed mb-2 mt-0">
                                <li className="list-inline-item mr-2">
                                    <span className="badge bg-pps-light-blue">{job?.province?.name}</span>
                                </li>
                                <li className="list-inline-item mr-2">
                                    <span className="badge bg-pps-light-blue">{job?.city?.name}</span>
                                </li>
                                <li className="list-inline-item mr-2">
                                    <span className="badge bg-pps-light-blue">{job?.hospital?.name}</span>
                                </li>
                            </ul>


                            <p>
                                <h6 className="font-weight-semibold">Job Description</h6>
                                {parse(htmlFormatter(job?.description))}
                            </p>

                            <p>
                                <h6 className="font-weight-semibold">Critical Outputs:</h6>
                                {parse(htmlFormatter(job?.critical_outputs))}
                            </p>

                            <p>
                                <h6 className="font-weight-semibold">Requirements:</h6>
                                {parse(htmlFormatter(job?.requirements))}
                            </p>


                            <h6 className="font-weight-semibold">Competencies:</h6>
                            <ul className="list">
                                {
                                    job?.competencies?.map((competency, index) => {
                                        return <li key={index}><strong className="d-block">{competency?.name}</strong>
                                        </li>
                                    })
                                }
                            </ul>
                            <h6 className="font-weight-semibold">Notes:</h6>
                            {parse(htmlFormatter(job?.notes))}

                            <h6 className="font-weight-semibold mt-1">Departments:</h6>
                            <ul className="list">
                                {
                                    job?.departments?.map((department, index) => {
                                        return <li key={index}><strong className="d-block">{department?.name}</strong>
                                        </li>
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
