import {APP_ID} from "../config";

function store_value(key, value) {
    localStorage.setItem(key, JSON.stringify(value))
}

function get_value(key) {
    let res = localStorage.getItem(key)
    if(res !== undefined) {
        return JSON.parse(res)
    }
    return null
}

function clear_storage() {
    localStorage.clear()
}



export const useAuthentication = () => {
    function user(){ return get_value(APP_ID)}

    function hasPermission(permission){
        if (user() !== null) {
            let permissions = user().permissions;
            if(permissions === undefined){
                clear_storage()
                return true
            }
            let perm = permissions.find(o => o.id === permission);
            return perm !== undefined;
        }
        return false
    }

    function setUser(details) {
        store_value(APP_ID, details)
    }

    function logout() {
        clear_storage()
    }

    return {user, setUser, logout, hasPermission}
}
