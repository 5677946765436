import {Button, Input} from "antd";
import logo from '../assets/images/logo.png';
import {useContext, useEffect, useState} from "react";
import {ApploadContext} from "../components/contexts/Appload";
import {ApiConsumer} from "../components/api/ApiConsumer";
import {API_URLS} from "../components/config";
import {useNotification} from "../components/hooks/NotificationHook";
import AuthenticationContext from "../components/contexts/Authentication";
import {NavLink} from "react-router-dom";
import {useHistory} from "react-router";
import {useAuthentication} from "../components/hooks/AuthHook";

const Login = () => {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    let {setLoading} = useContext(ApploadContext)
    let {login_user} = useContext(AuthenticationContext)
    let {user} = useAuthentication()
    let {ErrorMsg} = useNotification()
    let history = useHistory()

    function login() {
        setLoading(true)
        ApiConsumer.post(API_URLS.LOGIN, {username: username, password: password})
            .then(res => {
                login_user(res.data)
                window.location.replace('/#/');
            })
            .catch(err => {
                console.error(err)
                ErrorMsg('Credentials Invalid, please try again.')
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        if (user()) {
            history.push('/')
        }
    }, [user])

    return <>
        <div className='card col-4 offset-4 mt-5 mb-5' style={{backgroundColor: '#00a5e5'}}>
            <div className='row mt-5 mb-5'>
                <div className='col-12 text-center'>
                    <img className='text-center' src={logo} alt='Logo' width={400}/>
                    <Input className='col-12 mt-5' placeholder='Email Address' value={username}
                           onChange={event => setUsername(event.target.value)}/>
                    <Input className='col-12 mt-3' type='password' placeholder='Password' value={password}
                           onChange={event => setPassword(event.target.value)}/>
                    <div className='row'>
                        <div className='col-4 offset-4'>
                            <Button className='btn-outline-light mt-3 mb-5 col-12'
                                    style={{color: '#fff', backgroundColor: '#047dc4'}}
                                    onClick={() => login()}>Login</Button>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12' style={{marginTop: '-40px'}}>
                            <NavLink className={'text-center text-white'} to={'/forgotpassword'}>Forgot your password?</NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}
export default Login;
