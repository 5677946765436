import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Avatar, Carousel} from 'antd';
import {
    faBriefcase,
    faCity,
    faFolderOpen,
    faFolderPlus,
    faKey,
    faServer,
    faUsers,
    faLock,
    faWindowClose
} from "@fortawesome/free-solid-svg-icons";
import {useAuthentication} from "../hooks/AuthHook";
import {useEffect, useState} from "react";
import {ApiConsumer} from "../api/ApiConsumer";
import {API_BASE_URL, API_URLS} from "../config";
import {useHistory} from "react-router";

const Sidebar = () => {
    const [banners, setBanners] = useState([])
    let {user, logout} = useAuthentication()
    let history = useHistory();

    useEffect(() => {
        ApiConsumer.get(API_URLS.DATA.BANNERS)
            .then(res => setBanners(res.data))
            .catch(err => console.error(err))
    }, [])


    return <div className="sidebar sidebar-light sidebar-main sidebar-expand-md">
        <div className="sidebar-mobile-toggler text-center">
            <div className="sidebar-mobile-main-toggle">
                <i className="icon-arrow-left8"/>
            </div>
            Navigation
            <a href="#" className="sidebar-mobile-expand">
                <i className="icon-screen-full"/>
                <i className="icon-screen-normal"/>
            </a>
        </div>

        <div className="sidebar-content">
            <div className="sidebar-user-material">
                <div className="sidebar-user-material-body">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-3">
                                <Avatar className="img-fluid rounded-circle shadow-1" size="large">
                                    {`${user()?.name?.substr(0, 1)}${user()?.surname?.substr(0, 1)}`}
                                </Avatar>
                            </div>
                            <div className="col-9"style={{marginTop: '-10px', marginLeft: '-10px'}}>
                                <h6 className="mt-1 mb-0 font-weight-semibold">
                                    {`${user()?.name} ${user()?.surname}`}
                                </h6>
                                <span className="font-size-sm">{`${user()?.hospital?.name}`}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card card-sidebar-mobile">
                <ul className="nav nav-sidebar" data-nav-type="accordion">
                    <li className="nav-item" style={{height: '44px'}}>
                        <NavLink className="nav-link" to={'/'} exact>
                            <div className='row col-12 mt-0 mb-0'>
                                <div className='col-2'>
                                    <FontAwesomeIcon className='icon-briefcase3' icon={faBriefcase}/>
                                </div>
                                <div className='col-10'>
                                    <span>OPEN JOB POSTS</span>
                                </div>
                            </div>
                        </NavLink>
                    </li>

                    <li className="nav-item mb--3" style={{height: '44px'}}>
                        <NavLink className='nav-link' to={'/job'}>
                            <div className='row col-12'>
                                <div className='col-2'>
                                    <FontAwesomeIcon className='icon-folder-plus2' icon={faFolderPlus}/>
                                </div>
                                <div className='col-10'>
                                    <span>ADD NEW JOB POST</span>
                                </div>
                            </div>
                        </NavLink>
                    </li>

                    <li className="nav-item" style={{height: '44px'}}>
                        <NavLink className="nav-link" to={'/applications'}>
                            <div className='row col-12 mt-0 mb-0'>
                                <div className='col-2'>
                                    <FontAwesomeIcon className='icon-folder-open2' icon={faFolderOpen}/>
                                </div>
                                <div className='col-10'>
                                    <span>VIEW JOB APPLICATIONS</span>
                                </div>
                            </div>
                        </NavLink>
                    </li>

                    <li className="nav-item" style={{height: '44px'}}>
                        <NavLink className="nav-link" to={'/candidates'}>
                            <div className='row col-12 mt-0 mb-0'>
                                <div className='col-2'>
                                    <FontAwesomeIcon className='icon-server' icon={faServer}/>
                                </div>
                                <div className='col-10'>
                                    <span>CANDIDATE DATABASE</span>
                                </div>
                            </div>
                        </NavLink>
                    </li>

                    <li className="nav-item" style={{height: '44px'}}>
                        <NavLink className="nav-link" to={'/closed'}>
                            <div className='row col-12 mt-0 mb-0'>
                                <div className='col-2'>
                                    <FontAwesomeIcon className='icon-briefcase3' icon={faWindowClose}/>
                                </div>
                                <div className='col-10'>
                                    <span>CLOSED JOB POSTS</span>
                                </div>
                            </div>
                        </NavLink>
                    </li>
                    <li className="nav-item bg-danger-400" style={{height: '44px', cursor: 'pointer'}}>
                        <div className='nav-link' onClick={() => {
                            logout(null)
                            history.push('/login')
                            window.location.reload()
                        }}>
                            <div className='row col-12'>
                                <div className='col-2'>
                                    <FontAwesomeIcon className='icon-folder-plus2' icon={faLock}/>
                                </div>
                                <div className='col-10'>
                                    <span>LOGOUT</span>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            <div className="card">
                <div className="card-body">
                    <Carousel autoplay effect={'scrollx'} pauseOnHover={true}>
                        {
                            banners.map((banner, index) => {
                                return <img key={index}
                                            src={`${API_BASE_URL.replace('/api/v1', '')}${banner.image_url}`}
                                            className={banner.name}/>
                            })
                        }
                    </Carousel>
                </div>
            </div>
        </div>


    </div>
}
export default Sidebar;
