import {useContext, useEffect, useState} from "react";
import {TitleContext} from "../components/contexts/Title";
import {useParams} from "react-router-dom";
import {ApiConsumer} from "../components/api/ApiConsumer";
import {API_URLS} from "../components/config";
import {ApploadContext} from "../components/contexts/Appload";
import {Preview} from "../components/job/preview";
import {useAuthentication} from "../components/hooks/AuthHook";

const JobPreview = () => {
    const [detail, setDetail] = useState({})

    let {setTitle} = useContext(TitleContext);
    let {loading, setLoading} = useContext(ApploadContext)
    let {id} = useParams()
    let {user} = useAuthentication();

    useEffect(() => setTitle(`Jobs by ${user()?.name}`), [])

    useEffect(() => {
        setLoading(true)
        if (id !== null && id !== '' && id !== undefined) {
            ApiConsumer.get(API_URLS.JOBS.DETAIL(id))
                .then(res => {
                    setDetail(res.data)
                    setTitle(res.data?.title)
                })
                .catch(err => console.error(err))
                .finally(() => setLoading(false))
        } else {
            setLoading(false)
        }
    }, [id])

    return <>{!loading ? <Preview job={detail}/> : null}</>
}
export default JobPreview;
