export const APP_ID = "AHC-PORTAL";
export const API_KEY = "Test";

const API_VERSION = 'v1';
export let API_BASE_URL = `https://careerportal-api.africahealthcare.co.za/api/${API_VERSION}`

export const API_URLS = {
    LOGIN: `${API_BASE_URL}/users/login`,
    REGISTER: `${API_BASE_URL}/users/register/`,
    USER_LIST: `${API_BASE_URL}/users`,
    USER_DETAIL: function (user_id) {
      return `${API_BASE_URL}/users/detail/${user_id}/`
    },
    REQUEST_PIN: `${API_BASE_URL}/users/pin/`,
    PASSWORD_RESET: `${API_BASE_URL}/users/password/`,
    JOBS: {
        ADVERTISED: `${API_BASE_URL}/jobs/advertised/`,
        ADMIN_OPEN:  `${API_BASE_URL}/jobs/open`,
        OPEN: function (hospital_id) {
            return `${API_BASE_URL}/jobs/hospital/${hospital_id}`
        },
        CLOSED: function (hospital_id) {
            return `${API_BASE_URL}/jobs/closed/${hospital_id}`
        },
        CONTRACT_TYPE: `${API_BASE_URL}/jobs/types`,
        DETAIL: function (job_id) {
            return `${API_BASE_URL}/jobs/advertised/${job_id}/`
        },
        DEPARTMENTS: `${API_BASE_URL}/jobs/departments`,
        COMPETENCIES: `${API_BASE_URL}/jobs/competencies`,
        INDUSTRIES: `${API_BASE_URL}/jobs/industries`,
        TEMPLATES: `${API_BASE_URL}/jobs/templates`,
        TEMPLATES_DETAIL: function (template_id) {
            return `${API_BASE_URL}/jobs/templates/${template_id}/`
        },
    },
    APPLICATIONS: {
        OPEN: function (hospital_id) {
            return `${API_BASE_URL}/applications/hospital/${hospital_id}`
        },
        CANDIDATES: `${API_BASE_URL}/applications/candidates/all/`,
        DETAIL: function (application_id) {
            return `${API_BASE_URL}/applications/${application_id}/`
        },
        JOB: function (job_id) {
            return `${API_BASE_URL}/applications/job/${job_id}`
        }
    },
    DATA: {
        PROVINCES: `${API_BASE_URL}/data/provinces`,
        CITY_DETAIL: function (province_id) {
            return `${API_BASE_URL}/data/cities/${province_id}`
        },
        HOSPITALS: `${API_BASE_URL}/data/hospitals/`,
        BANNERS: `${API_BASE_URL}/data/banners/`,
    }
}
