import RichTextEditor from "react-rte";

function convertDateToString(date) {
    return new Date(date).toLocaleDateString('en-ZA').replace('/', '-').replace('/', '-')
}

function convertTimeToString(date) {
    return new Date(date).toLocaleTimeString();
}

function formatPhoneNumber(phoneNumberString) {
    let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        let intlCode = (match[1] ? '+1 ' : '');
        return [intlCode, match[2], ' ', match[3], ' ', match[4]].join('');
    }
    return null;
}

function htmlFormatter(value) {
    if (value === null || value === undefined || value === '')
        return '<p>None provided.</p>'
    return value
}

function textEditorFormatter(value) {
    if (value === null || value === undefined || value === '')
        return RichTextEditor.createEmptyValue()
    return RichTextEditor.createValueFromString(value, 'html');
}

function propertyExists(list, property) {
    if (!Array.isArray(list))
        return false

    return list.filter(obj => obj.id === property).length > 0
}

export const useUtils = () => {
    return {
        convertDateToString,
        formatPhoneNumber,
        htmlFormatter,
        textEditorFormatter,
        propertyExists,
        convertTimeToString
    }
}