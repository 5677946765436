import {Button, Input} from "antd";
import logo from '../assets/images/logo.png';
import {useContext, useState} from "react";
import {ApploadContext} from "../components/contexts/Appload";
import {ApiConsumer} from "../components/api/ApiConsumer";
import {API_URLS} from "../components/config";
import {useNotification} from "../components/hooks/NotificationHook";
import {NavLink} from "react-router-dom";
import {useHistory} from "react-router";

const ForgotPassword = () => {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [confirm, setConfirm] = useState('')
    const [requested, setRequested] = useState(false)
    const [pin, setPin] = useState('')
    const [emailPin, setEmailPin] = useState('')

    let {setLoading} = useContext(ApploadContext)
    let {SuccessMsg, ErrorMsg} = useNotification()
    let history = useHistory()

    function requestPin() {
        setLoading(true)
        ApiConsumer.post(API_URLS.REQUEST_PIN, {'email': username})
            .then(res => {
                setRequested(true)
                setPin(res.data.pin)
                SuccessMsg('Pin sent to your email. Please use it to reset your password')
            })
            .catch(err => {
                console.error(err)
                ErrorMsg('Could not request a pin, please try again.')
            })
            .finally(() => setLoading(false))
    }

    function reset() {
        setLoading(true)
        if (pin !== emailPin) {
            ErrorMsg('OTP does not match emailed OTP.')
            setLoading(false)
            return
        }
        if (password !== confirm) {
            ErrorMsg('Passwords do not match.')
            setLoading(false)
            return
        }

        ApiConsumer.post(API_URLS.PASSWORD_RESET, {'email': username, 'password': password})
            .then(() => {
                SuccessMsg('Your password has been reset!')
                history.push('/login')
            })
            .catch(err => {
                console.error(err)
                ErrorMsg('Credentials Invalid, please try again.')
            })
            .finally(() => setLoading(false))
    }

    return <>
        <div className='card col-4 offset-4 mt-5 mb-5 navbar-dark'>
            <div className='row mt-5 mb-5'>
                <div className='col-12 text-center'>
                    <img className='text-center' src={logo} alt='Logo' width={400}/>

                    {
                        !requested ? <Input className='col-12 mt-5' placeholder='Email Address' value={username}
                                            onChange={event => setUsername(event.target.value)}/>
                            :
                            <>
                                <Input className='col-12 mt-3' type='password' placeholder='OTP'
                                       value={emailPin} onChange={event => setEmailPin(event.target.value)}/>
                                <Input className='col-12 mt-3' type='password' placeholder='Password' value={password}
                                       onChange={event => setPassword(event.target.value)}/>
                                <Input className='col-12 mt-3' type='password' placeholder='Confirm Password'
                                       value={confirm} onChange={event => setConfirm(event.target.value)}/>
                            </>
                    }

                    <div className='row'>
                        <div className='col-4 offset-4'>
                            <Button className='btn-outline-success mt-3 mb-5 col-12'
                                    onClick={() => requested ? reset() : requestPin()}>{requested ? 'Reset Password' : 'Request Pin'}</Button>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12' style={{marginTop: '-40px'}}>
                            <NavLink className={'text-center'} to={'/login'}>Remember your password? Login</NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}
export default ForgotPassword;
