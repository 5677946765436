import {useEffect, useState} from "react";
import {HashRouter, Route, Switch, useHistory} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import AuthenticationContext from "./components/contexts/Authentication";
import {useAuthentication} from "./components/hooks/AuthHook";
import Login from "./pages/Login";
import MyJobs from "./pages/MyJobs";
import Header from "./components/header";
import {Footer} from "./components/footer";
import Sidebar from "./components/sidebar";
import JobDetails from "./pages/JobDetails";
import Title from "./components/title";
import Applications from "./pages/Applications";
import Hospitals from "./pages/Hospitals";
import Users from "./pages/Users";
import Admin from "./pages/Admin";
import Candidates from "./pages/Candidates";
import {Appload} from "./components/loader/appload";
import {TitleContext} from './components/contexts/Title'
import {ApploadContext} from "./components/contexts/Appload";
import JobPreview from "./pages/JobPreview";
import JobHistory from "./pages/JobHistory";
import ForgotPassword from "./pages/ForgotPassword";
import ClosedJobs from "./pages/ClosedJobs";
import ApplicationsByJob from "./pages/ApplicationsByJob";

function App() {
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [title, setTitle] = useState('');
    const [loading, setLoading] = useState(true)
    const [button, setButton] = useState(null);
    let history = useHistory();

    let {setUser, user} = useAuthentication();

    function login_user(user_details) {
        setLoggedIn(true)
        setUser(user_details)
    }

    function logoff_user() {
        setLoggedIn(false)
        setUser(null)
    }

    useEffect(() => {
        if (user()) {
            setLoggedIn(true)
            setLoading(false)
            return
        }
        if (window.location.pathname !== '/#/login')
            window.location.replace('/#/login');
        setLoading(false)
    }, [])

    return (
        <ApploadContext.Provider value={{loading, setLoading}}>
            <Appload page={
                <AuthenticationContext.Provider value={{isLoggedIn, login_user, logoff_user}}>
                    <TitleContext.Provider value={{title, button, setTitle, setButton}}>
                        <ToastContainer/>
                        <HashRouter history={history}>
                            {isLoggedIn ? <Header/> : null}
                            <div className="page-content">
                                {isLoggedIn ? <Sidebar/> : null}
                                <div className="content-wrapper">
                                    {isLoggedIn ? <Title/> : null}
                                    <div className="content">
                                        <div className="d-md-flex align-items-md-start">
                                            <div className="content p-0">
                                                <div className="tab-pane fade active show">
                                                    <Switch>
                                                        <Route path="/" component={MyJobs} exact/>
                                                        <Route path="/closed" component={ClosedJobs} exact/>
                                                        <Route path="/login" component={Login} exact/>
                                                        <Route path="/forgotpassword" component={ForgotPassword} exact/>
                                                        <Route path="/job" component={JobDetails} exact/>
                                                        <Route path="/jobs/:id/edit" component={JobDetails} exact/>
                                                        <Route path="/jobs/:id" component={JobPreview} exact/>
                                                        <Route path="/applications" component={Applications} exact/>
                                                        <Route path="/applications/:id" component={Applications} exact/>
                                                        <Route path="/applications/jobs/:id" component={ApplicationsByJob} exact/>
                                                        <Route path="/hospitals" component={Hospitals} exact/>
                                                        <Route path="/users" component={Users} exact/>
                                                        <Route path="/admin" component={Admin} exact/>
                                                        <Route path="/candidates" component={Candidates} exact/>
                                                        <Route path="/history" component={JobHistory} exact/>
                                                    </Switch>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {isLoggedIn ? <Footer/> : null}
                                </div>
                            </div>
                        </HashRouter>
                    </TitleContext.Provider>
                </AuthenticationContext.Provider>}
            />
        </ApploadContext.Provider>
    );
}

export default App;
