export const Footer = () => {
    return <div className="navbar navbar-expand-lg navbar-light">
        <div className="text-center d-lg-none w-100">
            <button type="button" className="navbar-toggler dropdown-toggle" data-toggle="collapse"
                    data-target="#navbar-footer">
                <i className="icon-unfold mr-2"/>
                Footer
            </button>
        </div>
        <div className="navbar-collapse collapse" id="navbar-footer">
            <span className="navbar-text">
                &copy; Copyright 2021 Africa Health Care. All Rights Reserved. <a href="#."
                                                                                  className="mr-1 ml-1">Contact Us</a> <a
                href="#." className="mr-1">Technical Support</a>
            </span>
        </div>
    </div>
}
