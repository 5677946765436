import {Input, Modal, Select} from "antd";
import {useContext, useEffect, useState} from "react";
import {useNotification} from "components/hooks/NotificationHook";
import {ApploadContext} from "components/contexts/Appload";
import {ApiConsumer} from "components/api/ApiConsumer";
import {API_URLS} from "components/config";

export function UserDetail({visible, detail, toggleDetail}) {

    const [hospitals, setHospitals] = useState([])
    const [details, setDetails] = useState(detail)

    let {SuccessMsg, ErrorMsg} = useNotification()
    let {setLoading} = useContext(ApploadContext)

    function save_user() {
        setLoading(true)
        if (details?.first_name === null || details?.first_name === undefined) {
            ErrorMsg('Please provide a name')
            setLoading(false)
            return
        }
        if (details?.last_name === null || details?.last_name === undefined) {
            ErrorMsg('Please provide a surname')
            setLoading(false)
            return
        }
        if (details?.contact_number === null || detail?.contact_number === undefined) {
            ErrorMsg('Please provide contact number')
            setLoading(false)
            return
        }
        if (details?.email === null || details?.email === undefined) {
            ErrorMsg('Please provide an email address')
            setLoading(false)
            return
        }

        ApiConsumer.put(API_URLS.USER_DETAIL(details?.id), details)
            .then(() => {
                SuccessMsg('User successfully updated!')
                window.location.reload()
            })
            .catch(() => ErrorMsg('Could not save the user.'))
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        setDetails(detail)
        console.log(detail)
    }, [detail])

    useEffect(() => {
        console.log(details)
    }, [details])


    useEffect(() => {
        const filters = {
            'size': 100,
            'page': 1,
            'user_type': 'all'
        }
        ApiConsumer(API_URLS.DATA.HOSPITALS, {
            params: filters,
            paramSerializer: function (params) {
                return JSON.stringify(params)
            }
        })
            .then(res => {
                setHospitals(res.data.data)
            })
            .catch(err => console.error(err))
    }, [])

    function handleOk() {

    }

    function handleCancel() {

    }

    return <Modal
        title="Add User"
        centered
        visible={visible}
        onOk={() => save_user()}
        onCancel={toggleDetail}
    >
        <div className='row'>
            <div className='row mt-2'>
                <div className='col'>
                    <label>First Name</label>
                    <Input value={details?.first_name} onChange={event => setDetails(prevState => ({
                        ...prevState,
                        ['first_name']: event.target.value
                    }))}/>
                </div>
                <div className='col'>
                    <label>Surname</label>
                    <Input value={details?.last_name} onChange={event => setDetails(prevState => ({
                        ...prevState,
                        ['last_name']: event.target.value
                    }))}/>
                </div>
            </div>
            <div className='row mt-2'>
                <div className='col'>
                    <label>Contact Number</label>
                    <Input value={details?.contact_number} onChange={event => setDetails(prevState => ({
                        ...prevState,
                        ['contact_number']: event.target.value
                    }))}/>
                </div>
                <div className='col'>
                    <label>Extension</label>
                    <Input value={detail?.extension} onChange={event => setDetails(prevState => ({
                        ...prevState,
                        ['extension']: event.target.value
                    }))}/>
                </div>
            </div>
            <div className='row mt-2'>
                <div className='col'>
                    <label>Select Hospital</label>
                    <Select
                        className='col-12'
                        showSearch
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        }
                        onChange={value => setDetails(prevState => ({
                            ...prevState,
                            ['hospital_id']: value
                        }))}
                        defaultValue={detail?.hospital?.name}
                    >
                        {
                            hospitals.map(hospital => {
                                return <Select.Option key={hospital.id}
                                                      value={hospital.id}>{`${hospital.name}`}</Select.Option>
                            })
                        }
                    </Select>
                </div>
                <div className='col'>
                    <label>Email</label>
                    <Input value={detail?.email} onChange={event => setDetails(prevState => ({
                        ...prevState,
                        ['email']: event.target.value
                    }))}/>
                </div>
            </div>
        </div>
    </Modal>
}
