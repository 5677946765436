import Job from "../components/job/card";
import {useContext, useEffect, useState} from "react";
import {Pagination} from 'antd';
import {ApiConsumer} from "../components/api/ApiConsumer";
import {API_URLS} from "../components/config";
import Loader from "../components/loader";
import Empty from "../components/empty";
import {TitleContext} from "../components/contexts/Title";
import {useAuthentication} from "../components/hooks/AuthHook";

export default function ClosedJobs () {
    const [jobs, setJobs] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [total, setTotal] = useState(0);

    let {setTitle, setButton} = useContext(TitleContext);
    let {user} = useAuthentication();

    useEffect(() => {
        setTitle(`Jobs by ${user()?.name}`)
        setButton(null)
    }, [])

    useEffect(() => {
        const filters = {
            'size': pageSize,
            'page': page
        }
        ApiConsumer(API_URLS.JOBS.CLOSED(user().hospital.id), {
            params: filters,
            paramSerializer: function (params) {
                return JSON.stringify(params)
            }
        })
            .then(res => {
                setJobs(res.data.data)
                setTotal(res.data?.count ?? 0)
                setPage(res.data?.current_page ?? 1)
                setPageSize(res.data?.size ?? 20)
            })
            .catch(err => console.error(err))
            .finally(() => {
                setLoaded(true)
                setTitle(`Jobs by ${user()?.name}`)
                setButton(null)
            })
    }, [page])

    return <>
        <div className='tab-pane fade active show'>
            <div className='row'>
                {
                    loaded ?
                        jobs.length === 0 ?
                            <Empty description={'No Jobs'}/> :
                            jobs.map((job, index) => {
                                return <Job job={job} key={index}/>
                            }) : <Loader/>
                }
            </div>
        </div>
        <div className="d-flex justify-content-center pt-3 mb-4">
            <Pagination className="pagination shadow-1 text-center"
                        total={total}
                        current={page}
                        pageSize={20}
                        onChange={selected => {
                            setPage(selected)
                        }}
            />
        </div>
    </>
}
