import {useContext, useEffect, useState} from "react";
import {Pagination} from 'antd';
import {ApiConsumer} from "../components/api/ApiConsumer";
import {API_URLS} from "../components/config";
import Loader from "../components/loader";
import {Row} from "../components/application/row";
import Empty from "../components/empty";
import {TitleContext} from "../components/contexts/Title";
import {useParams} from "react-router";

const ApplicationsByJob = () => {
    const [applications, setApplications] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [total, setTotal] = useState(0);

    let {setTitle} = useContext(TitleContext);
    let {id} = useParams()

    useEffect(() => {
        setTitle('Job Applications')
    }, [])

    useEffect(() => {
        setLoaded(false)
        const filters = {
            'size': pageSize,
            'page': page
        }
        ApiConsumer(API_URLS.APPLICATIONS.JOB(id), {
            params: filters,
            paramSerializer: function (params) {
                return JSON.stringify(params)
            }
        })
            .then(res => {
                setApplications(res.data.data)
                setTotal(res.data?.count ?? 0)
                setPage(res.data?.current_page ?? 1)
                setPageSize(res.data?.size ?? 20)
            })
            .catch(err => console.error(err))
            .finally(() => setLoaded(true))
    }, [page])

    return <>
        <div id="messages" className="card">
            <div className="card-body">
                {
                    loaded ?
                        applications.length === 0 ?
                            <Empty description={'No Applications'}/> :
                            applications.map(application => {
                                return <Row application={application}/>
                            }) : <Loader/>
                }
            </div>
        </div>


        <div className="d-flex justify-content-center pt-3 mb-4">
            <Pagination className="pagination shadow-1 text-center"
                        total={total}
                        current={page}
                        pageSize={20}
                        onChange={selected => {
                            setPage(selected)
                        }}
            />
        </div>
    </>
}
export default ApplicationsByJob;
