import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWindowClose} from "@fortawesome/free-solid-svg-icons";

export const Row = ({user, toggleModal, setModalDetail, toggleDelete}) => {
    return <tr key={user.id}>
        <td onClick={() => {
            setModalDetail(user)
            toggleModal()
        }} style={{"cursor": "pointer"}}>
            <strong>{`${user.first_name} ${user.last_name}`}</strong>
        </td>
        <td>{user.email}</td>
        <td>{user.contact_number}</td>
        <td>{user.hospital?.name ?? '-'}</td>
        <td onClick={() => {
            setModalDetail(user)
            toggleDelete()
        }} className='text-center' style={{"cursor": "pointer"}}><FontAwesomeIcon color={'#e74c3c'} icon={faWindowClose}/></td>
    </tr>
}
