import {useContext, useEffect, useState} from "react";
import {Pagination} from 'antd';
import {ApiConsumer} from "../components/api/ApiConsumer";
import {API_URLS} from "../components/config";
import Loader from "../components/loader";
import {Row} from "../components/hospitals/row";
import Empty from "../components/empty";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import {TitleContext} from "../components/contexts/Title";
import {HospitalModal} from "../components/hospitals/modal";

const Hospitals = () => {
    const [hospitals, setHospitals] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [total, setTotal] = useState(0);

    const [visible, setVisible] = useState(false)


    let {setTitle, setButton} = useContext(TitleContext);

    useEffect(() => {
        setTitle('Hospitals')
    }, [])


    useEffect(() => {
        const filters = {
            'size': pageSize,
            'page': page
        }
        ApiConsumer(API_URLS.DATA.HOSPITALS, {
            params: filters,
            paramSerializer: function (params) {
                return JSON.stringify(params)
            }
        })
            .then(res => {
                setHospitals(res.data.data)
                setTotal(res.data?.count ?? 0)
                setTitle(`Hospitals (${res.data?.count ?? 0})`)
                setPage(res.data?.page ?? 1)
                setPageSize(res.data?.size ?? 20)
            })
            .catch(err => console.error(err))
            .finally(() => setLoaded(true))
    }, [page])
    return <>
        <HospitalModal visible={visible} setVisible={setVisible}/>
        <div className="card">
            <div className="datatable-header">
                <div id="DataTables_Table_0_filter" className="dataTables_filter"><label><span>Filter:</span> <input
                    type="search" className="" placeholder="Type to filter..."
                    aria-controls="DataTables_Table_0"/></label></div>
                <div className="dataTables_length" id="DataTables_Table_0_length"><label><span>Show:</span> <select
                    name="DataTables_Table_0_length" aria-controls="DataTables_Table_0"
                    className="select2-hidden-accessible" tabIndex="-1" aria-hidden="true">
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                </select><span
                    className="select2 select2-container select2-container--default select2-container--below"
                    dir="ltr" style={{width: "auto"}}><span className="selection"><span
                    className="select2-selection select2-selection--single" role="combobox" aria-haspopup="true"
                    aria-expanded="false" tabIndex="0"
                    aria-labelledby="select2-DataTables_Table_0_length-6l-container"><span
                    className="select2-selection__rendered" id="select2-DataTables_Table_0_length-6l-container"
                    title="10">10</span><span className="select2-selection__arrow" role="presentation"><b
                    role="presentation"></b></span></span></span><span className="dropdown-wrapper"
                                                                       aria-hidden="true"/></span></label>
                </div>
            </div>
            <table className="table datatable-hospitals table-hover">

                <thead>
                <tr>
                    <th>Department</th>
                    <th>Main Contact</th>
                    <th>Main Contact Email</th>
                    <th>Main Contact Number / Ext</th>
                </tr>
                </thead>
                <tbody>
                {
                    loaded ?
                        hospitals.length === 0 ?
                            <tr>
                                <td colSpan={4}><Empty description={'No Hospitals'}/></td>
                            </tr> :
                            hospitals.map(hospital => {
                                return <Row hospital={hospital}/>
                            }) : <tr>
                            <td colSpan={4}><Loader/></td>
                        </tr>
                }
                </tbody>
            </table>
            <div className="datatable-footer">
                <div className="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">
                    Showing {page} to {pageSize} of {total} entries
                </div>
                <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
                    <Pagination className="pagination shadow-1 text-center"
                                total={total}
                                current={page}
                                pageSize={20}
                                onChange={selected => {
                                    setPage(selected)
                                }}
                    />
                </div>
            </div>
        </div>
    </>
}
export default Hospitals;
