import {Input, Modal, Select, Spin} from 'antd';
import {useContext, useEffect, useState} from "react";
import {ApiConsumer} from "../api/ApiConsumer";
import {API_URLS} from "../config";
import {useNotification} from "../hooks/NotificationHook";
import {ApploadContext} from "../contexts/Appload";

export const HospitalModal = ({visible, setVisible}) => {
    const [name, setName] = useState('')
    const [users, setUsers] = useState([])

    const [contact, setContact] = useState('')

    let {SuccessMsg, ErrorMsg} = useNotification()
    let {setLoading} = useContext(ApploadContext)

    function save_hospital() {
        if (name === '') {
            ErrorMsg('Please enter a hospital name')
            return
        }
        if (contact === '') {
            ErrorMsg('Please select a contact')
            return
        }
        setLoading(true)
        ApiConsumer.post(API_URLS.DATA.HOSPITALS, {'name': name, 'contact_id': contact})
            .then(() => {
                SuccessMsg('Hospital successfully saved!')
                window.location.reload()
            })
            .catch(() => ErrorMsg('Could not save the hospital.'))
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        const filters = {
            'size': 100,
            'page': 1,
            'user_type': 'all'
        }
        ApiConsumer(API_URLS.USER_LIST, {
            params: filters,
            paramSerializer: function (params) {
                return JSON.stringify(params)
            }
        })
            .then(res => {
                setUsers(res.data.data)
            })
            .catch(err => console.error(err))
    }, [])

    return <Modal
        title="Add Hospital"
        centered
        visible={visible}
        onOk={() => save_hospital()}
        onCancel={() => setVisible(false)}
    >
        <div className='row'>
            <div className='col-12'>
                <label>Hospital Name</label>
                <Input value={name} onChange={event => setName(event.target.value)}/>
            </div>
            <div className='col-12 mt-3'>
                <label>Select Main Contact</label>
                <Select
                    className='col-12'
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }
                    onChange={value => setContact(value)}
                >
                    {
                        users.map((user, index) => {
                            return <Select.Option key={index}
                                                  value={user.id}>{`${user.first_name} ${user.last_name}`}</Select.Option>
                        })
                    }
                </Select>
            </div>
        </div>
    </Modal>
}