import { createContext } from "react";

const UserProperties = {
    isLoggedIn: false,
    userType: null,
    userId: null,
    permissions:[],
    login_user: () => {
    },
    logoff_user: () => {
    }
}

const AuthenticationContext = createContext(UserProperties);

export default AuthenticationContext;
