import {API_BASE_URL, API_KEY, API_URLS} from "../config";

const axios = require("axios");
export const ApiConsumer = axios.create({baseURL: API_BASE_URL})

ApiConsumer.defaults.headers.common['Authorization'] = API_KEY;
ApiConsumer.interceptors.request.use(request => {

        /*if (axios.defaults.headers.common["Authorization"]) */
            return request;
        throw ({message: "the token is not available"});
    }, error => {
        console.log(error)
        return Promise.reject(error);
    }
);
