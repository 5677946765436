import {Input, Modal, Select} from 'antd';
import {useContext, useEffect, useState} from "react";
import {ApiConsumer} from "../api/ApiConsumer";
import {API_URLS} from "../config";
import {useNotification} from "../hooks/NotificationHook";
import {ApploadContext} from "../contexts/Appload";

export const UserModal = ({visible, setVisible, userType}) => {
    const [hospitals, setHospitals] = useState([])
    const [detail, setDetail] = useState('')

    let {SuccessMsg, ErrorMsg} = useNotification()
    let {setLoading} = useContext(ApploadContext)

    function save_user() {
        setLoading(true)
        if (detail?.first_name === null || detail?.first_name === undefined) {
            ErrorMsg('Please provide a name')
            setLoading(false)
            return
        }
        if (detail?.last_name === null || detail?.last_name === undefined) {
            ErrorMsg('Please provide a surname')
            setLoading(false)
            return
        }
        if (detail?.contact_number === null || detail?.contact_number === undefined) {
            ErrorMsg('Please provide contact number')
            setLoading(false)
            return
        }
        if (detail?.email === null || detail?.email === undefined) {
            ErrorMsg('Please provide an email address')
            setLoading(false)
            return
        }

        detail.user_type = userType

        ApiConsumer.post(API_URLS.REGISTER, detail)
            .then(() => {
                SuccessMsg('User successfully saved!')
                window.location.reload()
            })
            .catch(() => ErrorMsg('Could not save the user.'))
            .finally(() => setLoading(false))
    }


    useEffect(() => {
        const filters = {
            'size': 100,
            'page': 1,
            'user_type': 'all'
        }
        ApiConsumer(API_URLS.DATA.HOSPITALS, {
            params: filters,
            paramSerializer: function (params) {
                return JSON.stringify(params)
            }
        })
            .then(res => {
                setHospitals(res.data.data)
            })
            .catch(err => console.error(err))
    }, [])

    return <Modal
        title="Add User"
        centered
        visible={visible}
        onOk={() => save_user()}
        onCancel={() => setVisible(false)}
    >
        <div className='row'>
            <div className='row mt-2'>
                <div className='col'>
                    <label>First Name</label>
                    <Input value={detail?.first_name} onChange={event => setDetail(prevState => ({
                        ...prevState,
                        ['first_name']: event.target.value
                    }))}/>
                </div>
                <div className='col'>
                    <label>Surname</label>
                    <Input value={detail?.last_name} onChange={event => setDetail(prevState => ({
                        ...prevState,
                        ['last_name']: event.target.value
                    }))}/>
                </div>
            </div>
            <div className='row mt-2'>
                <div className='col'>
                    <label>Contact Number</label>
                    <Input value={detail?.contact_number} onChange={event => setDetail(prevState => ({
                        ...prevState,
                        ['contact_number']: event.target.value
                    }))}/>
                </div>
                <div className='col'>
                    <label>Extension</label>
                    <Input value={detail?.extension} onChange={event => setDetail(prevState => ({
                        ...prevState,
                        ['extension']: event.target.value
                    }))}/>
                </div>
            </div>
            <div className='row mt-2'>
                <div className='col'>
                    <label>Select Hospital</label>
                    <Select
                        className='col-12'
                        showSearch
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        }
                        onChange={value => setDetail(prevState => ({
                            ...prevState,
                            ['user_hospital_id']: value
                        }))}
                        value={detail?.user_hospital_id}
                    >
                        {
                            hospitals.map((user, index) => {
                                return <Select.Option key={index}
                                                      value={user.id}>{`${user.name}`}</Select.Option>
                            })
                        }
                    </Select>
                </div>
                <div className='col'>
                    <label>Email</label>
                    <Input value={detail?.email} onChange={event => setDetail(prevState => ({
                        ...prevState,
                        ['email']: event.target.value
                    }))}/>
                </div>
            </div>
        </div>
    </Modal>
}