import {Input, Modal, Select} from "antd";
import {useContext, useEffect, useState} from "react";
import {useNotification} from "components/hooks/NotificationHook";
import {ApploadContext} from "components/contexts/Appload";
import {ApiConsumer} from "components/api/ApiConsumer";
import {API_URLS} from "components/config";
import {faWindowClose} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export function UserDelete({visible, detail, toggleDetail}) {


    let {SuccessMsg, ErrorMsg} = useNotification()
    let {setLoading} = useContext(ApploadContext)

    function save_user() {
        setLoading(true)

        ApiConsumer.delete(API_URLS.USER_DETAIL(detail?.id))
            .then(() => {
                SuccessMsg('User successfully deleted!')
                window.location.reload()
            })
            .catch(() => ErrorMsg('Could not delete the user.'))
            .finally(() => setLoading(false))
    }

    return <Modal
        title="Delete User"
        centered
        visible={visible}
        onOk={() => save_user()}
        onCancel={toggleDetail}
    >
        <div className='row'>
            <div className='row mt-2 col-12'>
                <div className='col-1'>
                    <FontAwesomeIcon color={'#e74c3c'} size={'2x'} icon={faWindowClose}/>
                </div>
                <div className='col-10'>
                    <label>Are you sure you want to delete the user?</label>
                </div>
            </div>
        </div>
    </Modal>
}
