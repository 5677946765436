import {useUtils} from "../hooks/utils";
import parse from 'html-react-parser';
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {Modal} from "antd";
import {useContext, useState} from "react";
import {ApiConsumer} from "../api/ApiConsumer";
import {API_BASE_URL, API_URLS} from "../config";
import {ApploadContext} from "../contexts/Appload";
import {useNotification} from "../hooks/NotificationHook";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilePdf} from "@fortawesome/free-solid-svg-icons";

export const Row = ({application}) => {
    const [shortlist, setShortlist] = useState(false)
    const [reject, setReject] = useState(false)
    let {convertDateToString, convertTimeToString} = useUtils()
    let {setLoading} = useContext(ApploadContext)
    let {SuccessMsg, ErrorMsg} = useNotification();

    function ShortlistApplication() {
        setLoading(true)
        ApiConsumer.put(API_URLS.APPLICATIONS.DETAIL(application.id), {status: 'SHORT_LIST'})
            .then(() => {
                SuccessMsg('Job has successfully been closed.')
                window.location.reload()
            })
            .catch(() => ErrorMsg('Could not close job. Please try again later.'))
            .finally(() => setLoading(false))
    }

    function RejectApplication() {
        setLoading(true)
        ApiConsumer.put(API_URLS.APPLICATIONS.DETAIL(application.id), {status: 'REJECTED'})
            .then(() => {
                SuccessMsg('Job has successfully been closed.')
                window.location.reload()
            })
            .catch(() => ErrorMsg('Could not close job. Please try again later.'))
            .finally(() => setLoading(false))
    }

    return <div key={application.id} className="media m-0">
        <Modal
            title={`Confirm Close Job`}
            visible={shortlist}
            onOk={ShortlistApplication}
            icon={<ExclamationCircleOutlined/>}
            onCancel={() => setShortlist(false)}
            okText="Yes"
            cancelText="Cancel"
        >
            <p>Are you sure you want to shortlist this application?</p>
        </Modal>
        <Modal
            title={`Confirm Close Job`}
            visible={reject}
            onOk={RejectApplication}
            icon={<ExclamationCircleOutlined/>}
            onCancel={() => setReject(false)}
            okText="Yes"
            cancelText="Cancel"
        >
            <p>Are you sure you want to reject this application?</p>
        </Modal>
        <div className="media-body">
            <span className="badge bg-pps-blue mt-2">JOB: {application.job.title}</span>
            <h6 className="media-title">
                <p>{`${application.first_name} ${application.last_name}`}</p>
                <span className="ml-3 text-muted float-right">{convertDateToString(application.created)}</span>
                <span className="ml-3 text-muted float-right">{convertTimeToString(application.created)}</span>
            </h6>

            <span>{parse(application.job.description)}</span>
            <div className="clearfix"/>

            {
                application.status === 'REJECTED' ? <span className="badge bg-danger mt-2">Rejected</span> :
                    application.status === 'SHORT_LIST' ? <span className="badge bg-pps-gold mt-2">Shortlisted</span> :
                        <>
                            <button onClick={() => setShortlist(!shortlist)}
                                    className="btn btn-sm rounded-round bg-pps-blue btn-lg bg-pps-gold legitRipple mr-1 mt-1"
                                    data-toggle="modal" data-target="#modal-shortlist-application">Shortlist
                            </button>
                            <button onClick={() => setReject(!reject)}
                                    className="btn btn-sm rounded-round bg-pps-blue btn-lg bg-danger legitRipple mr-1 mt-1"
                                    data-toggle="modal" data-target="#modal-reject-application">Reject
                            </button>
                        </>
            }

            <a href={`${API_BASE_URL.replace('/api/v1', '')}${application.cv_file_url}`} target='_blank'
               className="btn btn-sm rounded-round bg-pps-blue btn-lg legitRipple mt-1 mr-1 float-right"> <FontAwesomeIcon icon={faFilePdf}/> CV</a>
            {
                application.cover_letter_file_url ?
                    <a href={`${API_BASE_URL.replace('/api/v1', '')}${application.cover_letter_file_url}`}
                       target='_blank'
                       className="btn btn-sm rounded-round bg-pps-blue btn-lg legitRipple mt-1 mr-1 float-right"><FontAwesomeIcon icon={faFilePdf}/> Cover
                        Letter</a> : null
            }
        </div>
    </div>
}
