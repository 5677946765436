import {useContext} from "react";
import {TitleContext} from "../contexts/Title";

const Title = () => {
    let {title, button} = useContext(TitleContext);

    return <div className="page-header page-header-light">
        <div className="page-header-content header-elements-md-inline">
            <div className="page-title d-flex">
                <h1>{title}</h1>
                <a href="#" className="header-elements-toggle text-default d-md-none">
                    <i className="icon-more"/>
                </a>
            </div>
            {
                button ? (button) : null
            }
        </div>
    </div>
}
export default Title;
