import Empty from "../../components/empty";
import Loader from "../../components/loader";
import {Input, Pagination} from "antd";
import {useContext, useEffect, useState} from "react";
import {ApiConsumer} from "../api/ApiConsumer";
import {API_BASE_URL, API_URLS} from "../config";
import {TitleContext} from "../contexts/Title";
import {UserModal} from "./modal";
import {CONSTANTS} from "components/config/constants";
import {NavLink} from "react-router-dom";

export const CandidatesTable = ({page_title, user_type, no_users = 'No Users'}) => {
    const [users, setUsers] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [total, setTotal] = useState(0);
    const [visible, setVisible] = useState(false)
    const [filters, setFilters] = useState({
        'size': pageSize,
        'page': page
    })

    let {setTitle, setButton} = useContext(TitleContext);

    useEffect(() => {
        setTitle(`${page_title}`)
        setButton(null)
    }, [])

    function search() {
        setLoaded(false)

        ApiConsumer(API_URLS.APPLICATIONS.CANDIDATES, {
            params: filters,
            paramSerializer: function (params) {
                return JSON.stringify(params)
            }
        })
            .then(res => {
                setUsers(res.data.data)
                setTotal(res.data?.count ?? 0)
                setTitle(`${page_title} (${res.data?.count ?? 0})`)
                setPage(res.data?.current_page ?? 1)
                setPageSize(res.data?.size ?? 20)
            })
            .catch(err => console.error(err))
            .finally(() => {
                setLoaded(true)
            })
    }

    useEffect(() => {
        search()
    }, [page])

    return <div className="card">
        <UserModal setVisible={setVisible} visible={visible} userType={user_type}/>
        <table className="table datatable-hospitals table-hover">
            <thead>
            <tr>
                <th colSpan={7}>
                    <div className="col-12">

                        <div className="btn btn-sm rounded-round bg-pps-blue btn-lg legitRipple ml-1 mt-1 float-right"
                             onClick={search}>
                            Search
                        </div>
                        <div className="mt-1 mr-2 float-right">
                            <Input value={filters?.search} onChange={event => setFilters(prevState => ({
                                ...prevState,
                                ['search']: event.target.value
                            }))}/>
                        </div>
                    </div>
                </th>

            </tr>
            </thead>
            <thead>
            <tr>
                <th>Name</th>
                <th>Surname</th>
                <th>Hospital</th>
                <th>Job</th>
                <th>Contact Number</th>
                <th>CV</th>
                <th>Cover Letter</th>
            </tr>
            </thead>
            <tbody>
            {
                loaded ?
                    users.length === 0 ?
                        <tr>
                            <td colSpan={6}><Empty description={no_users}/></td>
                        </tr> :
                        users.map((user, index) => {
                            return <tr key={index}>
                                <td>{user.first_name}</td>
                                <td>{user.last_name}</td>
                                <td>{user?.job?.hospital?.name}</td>
                                <td>{user?.job?.title}</td>
                                <td>{user.contact_number}</td>
                                <td><a href={`${API_BASE_URL.replace('/api/v1', '')}${user.cv_file_url}`}
                                       target='_blank'
                                       className="text-center">Download CV</a></td>
                                <td>
                                    {user.cover_letter_file_url ?
                                        <a href={`${API_BASE_URL.replace('/api/v1', '')}${user.cover_letter_file_url}`}
                                           target='_blank'
                                           className="text-center">Download Cover
                                            Letter</a> : <p>No coverletter submitted</p>
                                    }
                                </td>
                            </tr>
                        }) :
                    <tr>
                        <td colSpan={6}><Loader/></td>
                    </tr>
            }
            </tbody>
        </table>
        <div className="datatable-footer">
            <div className="dataTables_info" id="DataTables_Table_0_info" role="status"
                 aria-live="polite">
                Showing {page} to {pageSize} of {total} entries
            </div>
            <div className="dataTables_paginate paging_simple_numbers"
                 id="DataTables_Table_0_paginate">
                <Pagination className="pagination shadow-1 text-center"
                            total={total}
                            current={page}
                            pageSize={20}
                            onChange={selected => {
                                setPage(selected)
                            }}
                />
            </div>
        </div>
    </div>
}
