import logo from '../../assets/images/logo_light.png';
import {Popover, Button} from 'antd';
import {useAuthentication} from "../hooks/AuthHook";
import {DownCircleOutlined} from "@ant-design/icons";
import {useState} from "react";

const Header = () => {
    const [visible, setVisible] = useState(false)
    let {user} = useAuthentication()
    return <div className="navbar navbar-expand-md navbar-dark bg-pps-blue navbar-static fixed-top">
        <div className="navbar-brand pt-1 pb-1">
            <div className="d-inline-block">
                <img src={logo} alt="Africa health care logo"/>
            </div>
        </div>
        <div className="navbar-title">Careers Portal</div>
        <div className="d-md-none">
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-mobile">
                <i className="icon-tree5"></i>
            </button>
            <button className="navbar-toggler sidebar-mobile-main-toggle" type="button">
                <i className="icon-menu2"></i>
            </button>
        </div>

        <div className="collapse navbar-collapse" id="navbar-mobile">
            <ul className="navbar-nav ml-auto">
                <li className="nav-item dropdown dropdown-user">
                    <div className="navbar-nav-link d-flex align-items-center">
                        <span>{`${user()?.name} ${user()?.surname} `}</span>
                    </div>
                </li>
            </ul>
        </div>
    </div>
}
export default Header;
