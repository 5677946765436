import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/bootstrap.min.css'
import './assets/css/bootstrap_limitless.min.css'
import 'antd/dist/antd.min.css';
import './assets/css/colors.css';
import './assets/css/layout.css';
import './assets/css/components.css';
import './assets/css/custom.css';
import App from './App';
import './assets/app';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>,
    document.getElementById('root')
);

reportWebVitals();
