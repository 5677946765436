export const CONSTANTS = {
    USER_TYPES: {
        SYSTEM_USER: 'System User',
        ADMINISTRATOR: 'Administrator',
        CANDIDATE: 'Candidate'
    },
    JOB_STATUS: {
        CLOSED: 'CLOSED',
        DRAFT: 'DRAFT',
        PUBLISHED: 'PUBLISHED'
    }
}